<template>
    <div class="dashboard" :class="previewTab" :style="`--var-element-color: ${ contentForm.public_settings.element_color ? contentForm.public_settings.element_color : '#2f7eed' };--var-element-text-color: ${ contentForm.public_settings.element_text_color ? contentForm.public_settings.element_text_color : '#2c3e50' }; --var-radio-background-color: ${ contentForm.public_settings.element_color ? contentForm.public_settings.element_color : '#2f7eed' }; --var-element-border-color: ${ contentForm.public_settings.element_border_color ? contentForm.public_settings.element_border_color : '#C0DAFF' }; --var-element-bg-color: ${ contentForm.public_settings.element_bg_color ? contentForm.public_settings.element_bg_color : '#f5f5f5' };`">
        <div class="client_card">
            <div v-show="contentForm.public_settings.display_header == 1" class="card_header pointer"  @click="handleSetting('hdrsetting')" ref="hdrsection" :style="`background: ${contentForm.public_settings.header_bgcolor}; color: ${contentForm.public_settings.header_textcolor};`">
                <nav>
                    <img v-if="contentForm.public_settings.display_logo" class="logo_img" :src="contentForm.public_settings.logo ? contentForm.public_settings.logo : require('@/assets/images/thumb/default-logo.svg')" alt="logo">
                    <a>
                        <h4>Dashboard</h4>
                    </a>
                </nav>
                <div class="user_box" @click="accountDrop = !accountDrop">
                    <img :src="contentForm.public_settings.author ? contentForm.public_settings.author : user.profile_pic" :alt="user.full_name">
                    <div class="user_info">
                        <h5 :style="`color: ${contentForm.public_settings.header_textcolor};`">{{ user.full_name }} <i class="fas fa-caret-down" :class="`${accountDrop ? 'swing' : ''}`"></i></h5>
                    </div>
                    <ul class="account_drop text-inherit" :class="{ active : accountDrop }">
                        <li><i class="fas fa-home"></i> Primary Location</li>
                        <li><i class="far fa-user"></i> Profile Info</li>
                        <li><i class="far fa-file"></i> My Files</li>
                        <li><i class="fas fa-sign-out-alt"></i> Logout</li>
                    </ul>
                </div>
            </div>
            <!-- :class="device != 'desk' ? ( device == 'tab' && contentForm.public_settings.cover_type == 1 ? 'cover-image-md' : (contentForm.public_settings.cover_type == 1 ? 'cover-image-sm' : '')) :'cover-image'" -->
            <div v-show="contentForm.public_settings.display_cover && (device == 'desk' || (device != 'desk' && contentForm.public_settings.has_small_cover))" class="card_body" :class="device != 'desk' ? ( device !== 'desk' ? 'cover-image-sm' : 'cover-image-md') :'cover-image'" @click="handleSetting('cvrsetting')" ref="cvrsection">
                <picture v-if="(contentForm.public_settings.cover_type == 2 || contentForm.public_settings.cover_type == 3)">
                    <source v-if="contentForm.public_settings.small_cover != null && contentForm.public_settings.small_cover != '' && contentForm.public_settings.small_cover != 'null'" :srcset="`${contentForm.public_settings.small_cover}`" media="(max-width: 768px)">
                    <img v-if="device == 'desk'" :src="contentForm.public_settings.cover ? contentForm.public_settings.cover : require('@/assets/images/thumb/default-cover.svg')" alt="" class="banner_img">
                    <img v-else :src="contentForm.public_settings.small_cover ? contentForm.public_settings.small_cover : require('@/assets/images/thumb/default-cover-3-2.svg')" alt="" class="banner_img">
                </picture>
                <span v-if="contentForm.public_settings.cover_type == 1" class="overlay" :style="`background-color: ${contentForm.public_settings.cover_color ? contentForm.public_settings.cover_color : contentForm.public_settings.overlay_color};`"></span>
                <span v-else-if="contentForm.public_settings.cover_type == 2 && contentForm.public_settings.has_cover_overlay" class="overlay" :style="`background-color: ${contentForm.public_settings.overlay_color}; opacity: ${(contentForm.public_settings.opac_overlay_color == 'transparent' || !contentForm.public_settings.has_cover_overlay || contentForm.public_settings.overlay_opacity == 0) ? '0': ((contentForm.public_settings.overlay_opacity / 100 ))};`"></span>
                <span v-else-if="contentForm.public_settings.cover_type == 3 && contentForm.public_settings.has_cover_overlay" class="overlay" :style="`background-color: ${contentForm.public_settings.overlay_color}; opacity: ${(contentForm.public_settings.opac_overlay_color == 'transparent' || !contentForm.public_settings.has_cover_overlay || contentForm.public_settings.overlay_opacity == 0) ? '0': ((contentForm.public_settings.overlay_opacity / 100 ))};`"></span>
                <div class="banner_content" v-if="device == 'desk'">
                    <h4 v-if="contentForm.public_settings.cover_type == 1 || contentForm.public_settings.cover_type == 2" :style="`font-size: ${ ( contentForm.headline_setting && contentForm.headline_setting.font_size) ? contentForm.headline_setting.font_size : ''}px; line-height: ${( contentForm.headline_setting && contentForm.headline_setting.font_size) ? parseInt(contentForm.headline_setting.font_size) + 20 : ''}px; font-weight: ${ contentForm.headline_setting && contentForm.headline_setting.font_weight}; font-family: ${ contentForm.headline_setting && contentForm.headline_setting.font_family}; color: ${contentForm.headline_setting.font_color}; ${ contentForm.headline_setting && contentForm.headline_setting.has_shadow ? 'text-shadow: ' + contentForm.headline_setting.shadow_x +'px ' + contentForm.headline_setting.shadow_y + 'px ' + contentForm.headline_setting.shadow_blur + 'px ' + contentForm.headline_setting.shadow_color : 'none'}`">{{ contentForm.public_settings.headline }}</h4>
                    <h1 v-if="contentForm.public_settings.cover_type == 1 || contentForm.public_settings.cover_type == 2" :style="`font-size: ${ contentForm.subheadline_setting ? contentForm.subheadline_setting.font_size : ''}px; line-height: ${ contentForm.subheadline_setting ? (parseInt(contentForm.subheadline_setting.font_size) + 20) : ''}px; font-weight: ${ contentForm.subheadline_setting ? contentForm.subheadline_setting.font_weight : ''}; font-family: ${ contentForm.subheadline_setting ? contentForm.subheadline_setting.font_family : ''}; color: ${ contentForm.subheadline_setting && contentForm.subheadline_setting.font_color ? contentForm.subheadline_setting.font_color : ''}; ${ contentForm.subheadline_setting && contentForm.subheadline_setting.has_shadow ? 'text-shadow: ' + contentForm.subheadline_setting.shadow_x +'px ' + contentForm.subheadline_setting.shadow_y + 'px ' + contentForm.subheadline_setting.shadow_blur + 'px ' + contentForm.subheadline_setting.shadow_color : 'none'}`">{{ contentForm.public_settings.subheadline }}</h1>
                </div>
                <div class="banner_content" v-if="device == 'tab'">
                    <h4 v-if="contentForm.public_settings.cover_type == 1 || contentForm.public_settings.cover_type == 2" :style="`font-size: ${ ( contentForm.headline_setting && contentForm.headline_setting.font_size) ? contentForm.headline_setting.font_size / 2 : ''}px; line-height: ${( contentForm.headline_setting && contentForm.headline_setting.font_size) ? (parseInt(contentForm.headline_setting.font_size) + 20) / 2 : ''}px; font-weight: ${ contentForm.headline_setting && contentForm.headline_setting.font_weight}; font-family: ${ contentForm.headline_setting && contentForm.headline_setting.font_family}; color: ${contentForm.headline_setting.font_color}; ${ contentForm.headline_setting && contentForm.headline_setting.has_shadow ? 'text-shadow: ' + contentForm.headline_setting.shadow_x +'px ' + contentForm.headline_setting.shadow_y + 'px ' + contentForm.headline_setting.shadow_blur + 'px ' + contentForm.headline_setting.shadow_color : 'none'}`">{{ contentForm.public_settings.headline }}</h4>
                    <h1 v-if="contentForm.public_settings.cover_type == 1 || contentForm.public_settings.cover_type == 2" :style="`font-size: ${ contentForm.subheadline_setting ? contentForm.subheadline_setting.font_size / 1.5 : ''}px; line-height: ${ contentForm.subheadline_setting ? (parseInt(contentForm.subheadline_setting.font_size) + 20) / 2 : ''}px; font-weight: ${ contentForm.subheadline_setting ? contentForm.subheadline_setting.font_weight : ''}; font-family: ${ contentForm.subheadline_setting ? contentForm.subheadline_setting.font_family : ''}; color: ${ contentForm.subheadline_setting && contentForm.subheadline_setting.font_color  ? contentForm.subheadline_setting.font_color : ''}; ${ contentForm.subheadline_setting && contentForm.subheadline_setting.has_shadow ? 'text-shadow: ' + contentForm.subheadline_setting.shadow_x +'px ' + contentForm.subheadline_setting.shadow_y + 'px ' + contentForm.subheadline_setting.shadow_blur + 'px ' + contentForm.subheadline_setting.shadow_color : 'none'}`">{{ contentForm.public_settings.subheadline }}</h1>
                </div>
                <div class="banner_content" v-if="device == 'cell'">
                    <h4 v-if="contentForm.public_settings.cover_type == 1 || contentForm.public_settings.cover_type == 2" :style="`font-size: ${ ( contentForm.headline_setting && contentForm.headline_setting.font_size) ? (contentForm.headline_setting.font_size / 3 < 20 ? 20 : contentForm.headline_setting.font_size / 3) : ''}px; line-height: ${( contentForm.headline_setting && contentForm.headline_setting.font_size) ? (contentForm.headline_setting.font_size / 3 < 20 ? 26 : (parseInt(contentForm.headline_setting.font_size) + 20) / 3) : ''}px; font-weight: ${ contentForm.headline_setting && contentForm.headline_setting.font_weight}; font-family: ${ contentForm.headline_setting && contentForm.headline_setting.font_family}; color: ${contentForm.headline_setting.font_color}; ${ contentForm.headline_setting && contentForm.headline_setting.has_shadow ? 'text-shadow: ' + contentForm.headline_setting.shadow_x +'px ' + contentForm.headline_setting.shadow_y + 'px ' + contentForm.headline_setting.shadow_blur + 'px ' + contentForm.headline_setting.shadow_color : 'none'}`">{{ contentForm.public_settings.headline }}</h4>
                    <h1 v-if="contentForm.public_settings.cover_type == 1 || contentForm.public_settings.cover_type == 2" :style="`font-size: ${ contentForm.subheadline_setting ?( contentForm.subheadline_setting.font_size / 3 < 11 ? 11 :  contentForm.subheadline_setting.font_size / 3) : ''}px; line-height: ${ contentForm.subheadline_setting ? ( contentForm.subheadline_setting.font_size / 3 < 11 ? 16 : (parseInt(contentForm.subheadline_setting.font_size) + 20) / 3) : ''}px; font-weight: ${ contentForm.subheadline_setting ? contentForm.subheadline_setting.font_weight : ''}; font-family: ${ contentForm.subheadline_setting ? contentForm.subheadline_setting.font_family : ''}; color: ${ contentForm.subheadline_setting && contentForm.subheadline_setting.font_color  ? contentForm.subheadline_setting.font_color : ''}; ${ contentForm.subheadline_setting && contentForm.subheadline_setting.has_shadow ? 'text-shadow: ' + contentForm.subheadline_setting.shadow_x +'px ' + contentForm.subheadline_setting.shadow_y + 'px ' + contentForm.subheadline_setting.shadow_blur + 'px ' + contentForm.subheadline_setting.shadow_color : 'none'}`">{{ contentForm.public_settings.subheadline }}</h1>
                </div>
            </div>
        </div>
        <div class="dashboard_content py-5">
            <div class="form_area_video" :class="{'narrow' : previewTab == 'progress-tracking'}" v-if="previewPage == 'main'">
                <div class="section_item" :class="device == 'cell' ? 'mt-3' : 'mb-3'" ref="expvidsection" v-if="contentForm.public_settings.display_explainer_video && ((contentForm.public_settings.explainer_video && contentForm.public_settings.explainer_video_type == 0) || (contentForm.public_settings.explainer_embed && contentForm.public_settings.explainer_video_type == 1))">
                    <div class="video_wpr">
                        <div v-if="contentForm.public_settings.explainer_video_type == 0" v-html="parseEmbedCode(contentForm.public_settings.explainer_video)"></div>
                        <div v-if="contentForm.public_settings.explainer_video_type == 1" v-html="contentForm.public_settings.explainer_embed"></div>
                    </div>
                </div>
                <div @click="handleSetting('formsetting')">
                    <div v-if="previewTab == 'progress-tracking'">
                        <div class="tracking_title pt-2">
                            <h1>Your Wellness Journey</h1>
                            <!-- <h4>Monitor and celebrate your health milestones, every day.</h4> -->
                        </div>
                        <div class="category_label"><span>Body Composition</span></div>
                        <ul class="card_list">
                            <li>
                                <div class="water_card">
                                    <div class="top_area">
                                        <img src="@/assets/images/weight.svg" alt="Weight">
                                        <div class="main_title">Weight</div>
                                        <div class="sub_text">What's your weight today?</div>
                                        <div class="result">80 <span>kg</span></div>
                                    </div>
                                    <div class="bottom_area">
                                        <div class="input_section">
                                            <div class="field_box">
                                                <div class="field_area">
                                                    <input type="number" min="0" max="999999" placeholder="0">
                                                    <span class="unit">kg</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="notes">
                                            <button type="button" class="add_notes" @click="openNotes($event)"><i class="fas fa-plus"></i>Add A Note</button>
                                            <textarea rows="5" placeholder="Leave your notes here.."></textarea>
                                            <button type="button" class="save_btn">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="water_card">
                                    <div class="top_area">
                                        <img src="@/assets/images/body_fat.svg" alt="Body Fat">
                                        <div class="main_title">Body Fat</div>
                                        <div class="sub_text">What's your body fat percentage today?</div>
                                        <div class="result">20 <span>kg</span></div>
                                    </div>
                                    <div class="bottom_area">
                                        <div class="input_section">
                                            <div class="field_box">
                                                <div class="field_area">
                                                    <input type="number" min="0" max="999999" placeholder="0">
                                                    <span class="unit">%</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="notes">
                                            <button type="button" class="add_notes" @click="openNotes($event)"><i class="fas fa-plus"></i>Add A Note</button>
                                            <textarea rows="5" placeholder="Leave your notes here.."></textarea>
                                            <button type="button" class="save_btn">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="water_card">
                                    <div class="top_area">
                                        <img src="@/assets/images/fat-free-mass.svg" alt="Body Water">
                                        <div class="main_title">Fat-free Mass</div>
                                        <div class="sub_text">How much fat-free mass do you have?</div>
                                        <div class="result">0 <span>lbs</span></div>
                                    </div>
                                    <div class="bottom_area">
                                        <div class="input_section">
                                            <div class="field_box">
                                                <div class="field_area">
                                                    <input type="number" min="0" max="999999" placeholder="0">
                                                    <span class="unit">lbs</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="notes">
                                            <button type="button" class="add_notes" @click="openNotes($event)"><i class="fas fa-plus"></i>Add A Note</button>
                                            <textarea rows="5" placeholder="Leave your notes here.."></textarea>
                                            <button type="button" class="save_btn">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="water_card">
                                    <div class="top_area">
                                        <img src="@/assets/images/body-water.svg" alt="Body Water">
                                        <div class="main_title">Body Water</div>
                                        <div class="sub_text">How much body water do you have?</div>
                                        <div class="result">10 <span>L</span></div>
                                    </div>
                                    <div class="bottom_area">
                                        <div class="input_section">
                                            <div class="field_box">
                                                <div class="field_area">
                                                    <input type="number" min="0" max="999999" placeholder="0">
                                                    <span class="unit">l</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="notes">
                                            <button type="button" class="add_notes" @click="openNotes($event)"><i class="fas fa-plus"></i>Add A Note</button>
                                            <textarea rows="5" placeholder="Leave your notes here.."></textarea>
                                            <button type="button" class="save_btn">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="category_label"><span>Progress Pics</span></div>
                        <div class="water_card">
                            <div class="top_area">
                                <img src="@/assets/images/selfie.svg" alt="Selfie">
                                <div class="main_title">Progress Pictures</div>
                                <div class="sub_text">Are you submitting progress pics with this report?</div>
                            </div>
                            <div class="bottom_area">
                                <div class="grp_wpr">
                                    <label :for="`spr-${uniqueId}`" class="yes">
                                        <input type="radio" :value="1" :id="`spr-${uniqueId}`" name="is_submitting" v-model="is_submitting" class="mr-2" hidden>
                                        <div class="box_item"><i class="fas fa-thumbs-up"></i>Yes</div>
                                    </label>
                                    <label :for="`no_spr-${uniqueId}`" class="no">
                                        <input type="radio" :value="0" :id="`no_spr-${uniqueId}`" name="is_submitting" v-model="is_submitting" class="mr-2" hidden>
                                        <div class="box_item"><i class="fas fa-thumbs-down"></i>No</div>
                                    </label>
                                </div>
                                <div class="picture_section" v-if="is_submitting">
                                    <div class="input_section">
                                        <div class="field_box">
                                            <div class="field_area">
                                                <input type="text" placeholder="Caption goes here.." name="caption">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input_section mb-0">
                                        <label>Front Progress Picture</label>
                                        <div class="upload_image">
                                            <image-upload image-type="landscape" upload-text="Image" v-model="logo['front']"  :is-avatar="false" />
                                        </div>
                                    </div>
                                    <div class="input_section mb-0">
                                        <label>Side Progress Picture</label>
                                        <div class="upload_image">
                                            <image-upload image-type="landscape" upload-text="Image" v-model="logo['side']"  :is-avatar="false" />
                                        </div>
                                    </div>
                                    <div class="input_section mb-0">
                                        <label>Back Progress Picture</label>
                                        <div class="upload_image">
                                            <image-upload image-type="landscape" upload-text="Image" v-model="logo['back']"  :is-avatar="false" />
                                        </div>
                                    </div>
                                </div>
                                <div class="notes mt-4">
                                    <button type="button" class="add_notes" @click="openNotes($event)"><i class="fas fa-plus"></i>Add A Note</button>
                                    <textarea rows="5" placeholder="Leave your notes here.."></textarea>
                                    <button type="button" class="save_btn">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form_area">
                        <div class="setting_wpr">
                            <div @click="handleSetting('catsetting')" ref="catsection" v-if="previewTab == 'habit-tracking'">
                                <div class="cat_panel active">
                                    <h3 class="category_title" @click="accFunc($event)">Sleep
                                        <span class="show" :style="`color:${contentForm.public_settings.element_color ? contentForm.public_settings.element_color : '#2f7eed'}`">Show</span><span class="hide">Hide</span>
                                    </h3>
                                    <div class="quest_panel">
                                        <div class="quest_wpr">
                                            <div class="habit_title"><label>1</label><div class="title">Did you sleep at least 7 hours last night?</div></div>
                                            <div class="habit_details">
                                                <div class="require_wpr">
                                                    <p>Aim for 7+ hours of sleep per night to optimize health.</p>
                                                </div>
                                                <div class="grp_wpr">
                                                    <label :for="`movement-1-${uniqueId}`" class="yes">
                                                        <input type="radio" value="yes" :id="`movement-1-${uniqueId}`" :name="`move-1-${uniqueId}`" class="mr-2" hidden>
                                                        <div class="box_item"><i class="fas fa-thumbs-up mr-2"></i>Yes</div>
                                                    </label>
                                                    <label :for="`no_movement-1-${uniqueId}`" class="no">
                                                        <input type="radio" value="no" :id="`no_movement-1-${uniqueId}`" :name="`move-1-${uniqueId}`" class="mr-2" hidden>
                                                        <div class="box_item"><i class="fas fa-thumbs-down mr-2"></i>No</div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="cat_panel active">
                                    <h3 class="category_title" @click="accFunc($event)">Hydration
                                        <span class="show" :style="`color:${contentForm.public_settings.element_color ? contentForm.public_settings.element_color : '#2f7eed'}`">Show</span><span class="hide">Hide</span>
                                    </h3>
                                    <div class="quest_panel">
                                        <div class="quest_wpr">
                                            <div class="habit_title"><label>2</label><div class="title">Did you drink at least a gallon of water today?</div></div>
                                            <div class="habit_details">
                                                <div class="require_wpr">
                                                    <p>Aim for 1 gallon of water per day to optimize health.</p>
                                                </div>
                                                <div class="grp_wpr">
                                                    <label :for="`movement-2-${uniqueId}`" class="yes">
                                                        <input type="radio" value="yes" :id="`movement-2-${uniqueId}`" :name="`move-2-${uniqueId}`" class="mr-2" hidden>
                                                        <div class="box_item"><i class="fas fa-thumbs-up mr-2"></i>Yes</div>
                                                    </label>
                                                    <label :for="`no_movement-2-${uniqueId}`" class="no">
                                                        <input type="radio" value="no" :id="`no_movement-2-${uniqueId}`" :name="`move-2-${uniqueId}`" class="mr-2" hidden>
                                                        <div class="box_item"><i class="fas fa-thumbs-down mr-2"></i>No</div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="cat_panel active">
                                    <h3 class="category_title" @click="accFunc($event)">Vitamins
                                        <span class="show" :style="`color:${contentForm.public_settings.element_color ? contentForm.public_settings.element_color : '#2f7eed'}`">Show</span><span class="hide">Hide</span>
                                    </h3>
                                    <div class="quest_panel">
                                        <div class="quest_wpr">
                                            <div class="habit_title"><label>1</label><div class="title">Did you take your vitamins today?</div></div>
                                            <div class="habit_details">
                                                <div class="require_wpr">
                                                    <p>Aim for 1 dose of quality multivitamins per day to optimize health.</p>
                                                </div>
                                                <div class="grp_wpr">
                                                    <label :for="`movement-3-${uniqueId}`" class="yes">
                                                        <input type="radio" value="yes" :id="`movement-3-${uniqueId}`" :name="`move-3-${uniqueId}`" class="mr-2" hidden>
                                                        <div class="box_item"><i class="fas fa-thumbs-up mr-2"></i>Yes</div>
                                                    </label>
                                                    <label :for="`no_movement-3-${uniqueId}`" class="no">
                                                        <input type="radio" value="no" :id="`no_movement-3-${uniqueId}`" :name="`move-3-${uniqueId}`" class="mr-2" hidden>
                                                        <div class="box_item"><i class="fas fa-thumbs-down mr-2"></i>No</div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="submit_section">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Member ID</label>
                                        <div class="field_wpr m_id_field">
                                            <input type="text" placeholder="ID goes here.." >
                                        </div>
                                    </div>
                                </div>
                                <div @click="handleSetting('subbtnsetting')" ref="subbtnsection">
                                    <button type="button" class="primary_btn" :style="`background-color: ${ contentForm.public_settings.submit_btn_bgcolor ? contentForm.public_settings.submit_btn_bgcolor : '' }; color: ${ contentForm.public_settings.submit_btn_textcolor ? contentForm.public_settings.submit_btn_textcolor : '' };`">{{ contentForm.public_settings.submit_btn_text ? contentForm.public_settings.submit_btn_text : 'Submit' }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="previewPage == 'thankyou'" class="form-area-thankyou" @click="handleSetting('thankyousetting')" ref="thankyousection" :style="`background: ${contentForm.public_settings.thankyou_bgcolor}; color: ${contentForm.public_settings.thankyou_textcolor};`">
                  <div class="setting_wpr" :style="`background: ${contentForm.public_settings.thankyou_bgcolor}; color: ${contentForm.public_settings.thankyou_textcolor};`">
                    <p v-if="previewTab == 'progress-tracking'">Progress report submitted! You will now be able to view your updated progress in your progress dashboard.</p>
                      <p v-if="previewTab == 'habit-tracking'">Thanks for submitting your habits! You can monitor your progress via your habits dashboard. <a href="javascript:void(0);" class="link" :style="`color: ${contentForm.public_settings.thankyou_textcolor};`">click here</a>.</p>
                  </div>
            </div>
        </div>
        <footer class="dashboard_footer" v-show="contentForm.public_settings.display_footer == 1" @click="handleSetting('ftrsetting')" ref="ftrsection" :style="`background: ${contentForm.public_settings.footer_bgcolor};`">
            <ul>
                <li class="pointer" v-show="contentForm.public_settings.footer_has_facebook"><a :href="contentForm.footer_facebook" :style="`border-color: ${contentForm.public_settings.footer_textcolor}; color: ${contentForm.public_settings.footer_textcolor};`"><i class="fab fa-facebook-f"></i></a></li>
                <li class="pointer" v-show="contentForm.public_settings.footer_has_twitter"><a :href="contentForm.footer_twitter" :style="`border-color: ${contentForm.public_settings.footer_textcolor}; color: ${contentForm.public_settings.footer_textcolor};`"><i class="fab fa-twitter"></i></a></li>
                <li class="pointer" v-show="contentForm.public_settings.footer_has_instagram"><a :href="contentForm.footer_instagram" :style="`border-color: ${contentForm.public_settings.footer_textcolor}; color: ${contentForm.public_settings.footer_textcolor};`"><i class="fab fa-instagram"></i></a></li>
            </ul>
            <h4 :style="`color: ${contentForm.public_settings.footer_textcolor};`">{{ contentForm.public_settings.footer_company ?  contentForm.public_settings.footer_company : `© ${new Date().getFullYear()} - ${user.businessname ? user.businessname : 'Company Name'}` }}</h4>
            <p>
                <a :href="contentForm.public_settings.footer_terms" :style="`color: ${contentForm.public_settings.footer_textcolor};`">Terms</a>
                <a :href="contentForm.public_settings.footer_policy" :style="`color: ${contentForm.public_settings.footer_textcolor};`">Privacy</a>
                <template v-if="membership.has_white_label && whiteLabel.has_label == 1">
                    <a v-if="whiteLabel.has_link == 1" :style="`color: ${contentForm.public_settings.footer_textcolor};`" :href="whiteLabel.link"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                    <a v-else :style="`color: ${contentForm.public_settings.footer_textcolor};`" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                </template>
                <a v-else :style="`color: ${contentForm.public_settings.footer_textcolor};`" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by Thrive Coach</a>
            </p>
        </footer>
    </div>
</template>
<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState } from 'vuex'
    import { uuid } from 'vue-uuid'

    const ImageUpload = defineAsyncComponent(() => import('@/components/image-library/ImageUpload'))

    import VideoParser from '@/utils/VideoParser'

    export default {
        name: 'Progress Tracking Preview Content',

        data () {
            return {
                accountDrop: false,
                logo: [],
                weekdays: [
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                    'Saturday',
                    'Sunday'
                ],
                is_submitting: 0,
                whiteLabel: {},
                uniqueId: uuid.v4(),
            }
        },

        props: {
            contentForm: Object,
            device: {
                type: String,
                default: 'cell'
            },
            previewPage: {
                type: String,
                default: 'main'
            },
            previewTab: String,
        },

        emit: [ 'update:modelValue' ],

        components: {
            ImageUpload
        },

        computed: mapState({
            user: state => state.authModule.user,
            membership: state => state.authModule.membership,
        }),

        mounted () {
            const vm = this;

            vm.whiteLabel = vm.user.white_label ? JSON.parse(JSON.stringify(vm.user.white_label)) : {};
        },

        methods:{
            parseEmbedCode (url) {
                const vm = this;

                return VideoParser.embedCodeFromUrl(url)
            },

            goTo (refName) {
                const vm = this;

                setTimeout(function () {
                    let element = vm.$refs[refName];

                    setTimeout(function() {
                        if (element) {
                            let top = element.offsetTop;
                            document.querySelector('.preview_content .content_area').scrollTo(0, top);
                        }
                    }, 100);
                }, 10);
            },

            handleSetting(val) {
                const vm = this;

                // if (vm.$parent.$parent && vm.$parent.$parent.setting) {
                //     vm.$parent.$parent.setting(val);
                // } else {
                //     vm.$parent.setting(val);
                // }
            },

            accFunc (e) {
                let el = e.currentTarget.closest('.cat_panel');
                if (el.classList.contains('active')) {
                    el.classList.remove('active');
                } else {
                    el.classList.add('active');
                }
            },

            openNotes(ev){
                let showElm = ev.currentTarget.closest('.notes');

                if(showElm.classList.contains('active')){
                    showElm.classList.remove('active');
                }else{
                    showElm.classList.add('active');
                }

            },
        }
    }
</script>

<style scoped>
    .area_body .dashboard.progress-tracking{
        background: #fafafb;
    }
    .tab .dashboard_content {
        margin: 0;
    }

    .cell .dashboard_content {
        margin: 0;
        padding: 0 15px;
    }

    .client_card {
        width: 100%;
        border: 0;
    }

    .client_card .card_body h1 {
        font-size: 60px;
        line-height: 75px;
        text-transform: capitalize;
        text-align: center;
        margin-bottom: 15px;
    }

    .tab .client_card .card_body h1 {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 10px;
    }

    .client_card .card_body h4 {
        font-size: 28px;
        line-height: 38px;
        text-transform: capitalize;
        text-align: center;
        letter-spacing: 0;
        font-weight: 500;
    }

    .tab .client_card .card_body h4 {
        font-size: 18px;
        line-height: 25px;
    }

    .dashboard_footer {
        width: 100%;
        text-align: center;
    }

    .goal_btn {
        background: #f2a31d;
        padding: 12px 15px;
        font-size: 17px;
        line-height: 22px;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        font-weight: 500;
        border-radius: 8px;
        text-align: center;
        text-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        margin: 20px 0;
    }

    .goal_btn i {
        margin-right: 15px;
        color: #32373b;
        font-size: 22px;
    }

    /* .primary_btn {
        margin: 30px 0;
        letter-spacing: 2px;
        text-transform: uppercase;
        cursor: pointer;
    } */

    /* Page Preview */
    .cell .dashboard {
        margin: 0;
        display: flex;
        flex-direction: column;
        height: calc(750px - 140px);
    }

    .cell .client_card {
        border-radius: 6px;
    }

    .cell .client_card .card_header {
        padding: 15px 20px;
    }

    .cell .client_card .card_header .user_box>img {
        width: 20px;
        height: 20px;
    }

    .cell .client_card .card_header .user_box h5 {
        font-size: 11px;
        line-height: 14px;
    }

    /* .cell .client_card .card_header h4 {
        font-size: 14px;
        line-height: 20px;
    } */

    .cell .client_card .card_body {
        text-align: center;
    }

    .cell .client_card .card_body .banner_content {
        padding: 0 15px;
    }

    .cell .client_card .card_body h1 {
        font-size: 17px;
        line-height: 30px;
        margin: 0;
        font-weight: 600;
        color: #fff;
        position: relative;
        z-index: 3;
    }

    .cell .client_card .card_body h4 {
        font-size: 10px;
        line-height: 16px;
        font-weight: 500;
        color: #fff;
        text-transform: initial;
        position: relative;
        z-index: 3;
    }

    .cell .client_card .card_body .overlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
    }
    .cell .form_grp {
        padding: 15px 0;
    }

    .cell .setting_wpr .group_item .input_label {
        font-size: 13px;
        line-height: 17px;
    }
    .cell .field_wpr textarea {
        height: 52px;
        font-size: 11px;
        padding: 5px 10px;
    }

    .cell .field_wpr.has_suffix .suffix {
        font-size: 11px;
    }

    .require_wpr{
        padding: 0 0 5px 37px;
    }
    .cell .require_wpr{
        padding: 0 0 5px 32px;
    }

    .require_wpr p{
        font-size: 14px;
        line-height: 22px;
        font-weight: 300;
        color: #656565;
    }
    .cell .require_wpr p{
        font-size: 12px;
        line-height: 20px;
    }

    .require_wpr h4 {
        font-size: 16px;
        line-height: 20px;
        color: 32373b;
        font-weight: 500;
    }

    .tab .require_wpr h4 {
        font-size: 14px;
        line-height: 19px;
    }

    .cell .require_wpr p {
        font-size: 12px;
        line-height: 18px;
    }
    .cell .require_wpr{
        border-radius: 4px;
    }
    .cell .require_wpr h4 {
        font-size: 12px;
        line-height: 15px;
    }

    .cell h3.sub_header {
        font-size: 16px;
        line-height: 20px;
        margin: 20px 0 10px;
    }

    .cell .day_sorting li {
        min-width: 75px;
    }

    .cell .day_sorting li label {
        padding: 5px 10px;
    }

    .cell .day_sorting li label p {
        font-size: 10px;
    }

    .cell .goal_btn {
        padding: 7px 10px;
        font-size: 11px;
        line-height: 14px;
        border-radius: 4px;
    }

    .cell .goal_btn i {
        font-size: 14px;
    }

    .cell .day_sorting li label span {
        transform: scale(0.8);
    }

    :deep(.video_wpr) {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        padding-top: 25px;
    }

    :deep(.video_wpr iframe) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }

    :deep(.tab_content) {
        height: 100%;
    }
    .quest_panel{
        margin-bottom: 40px;
        padding: 0 25px;
        background: #fff;
        border-radius: 6px;
        border: 1px solid #f5f5f5;
    }
    .cell .quest_panel{
        padding: 0 15px;
    }
    .dashboard_content h3.category_title {
        font-size: 18px;
        line-height: 25px;
        color: #121525;
        font-weight: 500;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 15px;
    }
    .cell .dashboard_content h3.category_title{
        font-size: 13px;
        line-height: 18px;
    }
    .quest_wpr{
        border: 1px solid #e9e9e9;
        padding: 25px 30px;
        border-radius: 6px;
        margin: 30px 0;
        background: #fff;
    }
    .cell .quest_wpr{
        padding: 15px;
    }
    /* .quest_wpr{
        background: #fff;
        border-radius: 10px;
        margin: 25px 0;
        position: relative;
        padding: 15px;
        background: #fff;
        border-radius: 6px;
        border: 1px solid #e9e9e9;
    } */
    .progress_title{
        display: flex;
        align-items: flex-start;
        gap: 12px;
    }
    .progress_title .title, .progress_title .title :deep(p) {
        flex: 1 1 auto;
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        color: var(--var-element-text-color);
    }
    .progress_title label{
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 1px solid var(--var-element-color);
        color: var(--var-element-color);
        font-size: 9px;
        line-height: 18px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        flex-shrink: 0;
        margin-top: 2px;
    }
    .progress_details{
        padding: 20px 0;
    }
    .left_spacing{
        padding-left:30px
    }
    .progress_details .field_area{
        margin: 5px 0;
        position: relative;
    }
    .progress_details .field_area input{
        height: 40px;
        width: 100%;
        padding: 0 15px;
        font-size: 11px;
        color: #5a5a5a;
        border: 1px solid #e9e9e9;
        border-radius: 4px;
    }
    .progress_details .field_area input[type=number]::-webkit-inner-spin-button, .progress_details .field_area input[type=number]::-webkit-outer-spin-button{
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    .progress_details .field_area.has_suffix input{
        padding-right: 75px;
    }
    .progress_details .field_area.has_suffix .suffix{
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 75px;
        border-left: 1px solid #e9e9e9;
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .progress_details .field_area textarea{
        height: 80px;
        width: 100%;
        padding: 10px;
        font-size: 11px;
        color: #5a5a5a;
        border: 1px solid #e9e9e9;
        border-radius: 4px;
        resize: none;
    }
    .grp_wpr {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        padding: 20px 0 25px 37px;
    }
    .cell .grp_wpr{
        padding: 20px 0 25px 32px;
    }

    .grp_wpr label .box_item{
        font-size: 13px;
        line-height: 15px;
        border-radius: 4px;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        opacity: 0.5;
        transition: all 0.3s ease-in-out;
    }
    .grp_wpr label .box_item i{
        font-size: 13px;
        margin-right: 10px;
    }
    .grp_wpr label.yes .box_item {
        border: 1px solid #2f7eed30;
        color: #2f7eed;
    }
    .grp_wpr label.no .box_item {
        border: 1px solid #d9d9d9;
        color: #777;
    }
    .grp_wpr label input[type="radio"]:checked ~ .box_item {
        opacity: 1;
    }
    .quest_wpr h6{
        font-size: 12px;
        line-height: 15px;
        font-weight: 500;
        color: #5a5a5a;
        margin-bottom: 5px;
    }
    .sec_wpr{
        padding-bottom: 20px;
    }
    .form_grp {
        padding: 10px 0;
    }
    .field_wpr{
        background: #fff;
    }
    .field_wpr input {
        height: 45px;
        font-size: 13px;
    }
    .primary_btn {
        height: 55px;
        letter-spacing: 1px;
        font-size: 16px;
        margin: 30px 0 40px 0;
        text-transform: uppercase;
        cursor: pointer;
    }
    .opt_item {
        flex: 0 0 30%;
        padding: 5px 15px;
    }

    .cell .opt_item {
        flex: auto;
        padding: 0px 2px;
    }

    .opt_item.Week_btn {
        flex-basis: 100%;
    }

    .success_goal_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 55px;
        border-radius: 6px;
        background: #2F7FED;
        color: #fff;
        font-size: 20px;
        line-height: 25px;
        font-weight: 500;
        width: 100%;
        border: 0;
    }

    .form_grp .opt_item input[type='checkbox'] {
        -webkit-appearance: none;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        outline: none;
        border: 2px solid var(--var-element-color);
    }

    .form_grp .opt_item input[type='checkbox']:before {
        content: '';
        display: block;
        width: 80%;
        height: 80%;
        margin: 10%;
        border-radius: 50%;
    }

    .form_grp .opt_item input[type="checkbox"]:checked:before {
        background: var(--var-element-color);
    }

    .form_grp .opt_item input[type="checkbox"]:checked {
        border-color: var(--var-element-color);
    }

    .logo_img {
        max-width: 100%;
        height: auto;
        max-height: 25px;
    }

    .client_card .card_header .user_box {
        margin-left: auto;
    }

    .form_area_video {
        max-width: 800px;
        margin: 20px auto 50px auto;
        flex: 1;
    }
    .tab .form_area_video.narrow {
        max-width: 400px;
    }

    .cell .dashboard_content .field_wpr :deep(textarea){
        height: 45px;
        font-size: 10px;
        line-height: 12px;
        padding: 5px 10px;
    }

    :deep(.cell .tab_content .upload_wpr .info) {
        text-align: center;
    }

    :deep(.cell .tab_content .upload_wpr img) {
        max-width: 70%;
        height: auto;
        font-size: 9px;
        line-height: 15px;
        color: #747474;
        font-weight: 500;
        margin: 10px 0;
    }

    :deep(.cell .upload_area h4) {
        font-size: 9px;
        line-height: 15px;
        color: #575757;
        font-weight: 500;
        margin: 14px 0 0;
        display: block!important;
    }

    :deep(.cell .upload_wpr .upload_area) {
        height: 110px;
        min-height: 80px;
    }
    .field_wpr.has_suffix{
        padding-right: 100px;
    }
    .field_wpr.has_suffix .suffix{
        width: 100px;
    }
    .picture_section .upload_wpr .upload_area{
        border-radius: 0;
    }
    .picture_section :deep(.upload_wpr img){
        width: 70px;
        height: auto;
    }
    .picture_section :deep(.upload_wpr h4){
        font-size: 13px;
        line-height: 16px;
    }
    .picture_section :deep(.upload_wpr .info){
        text-align: center;
    }
    .tab .picture_section :deep(.upload_wpr img){
        width: 50px;
        height: auto;
    }
    .tab .picture_section :deep(.upload_wpr h4){
        font-size: 11px;
        line-height: 15px;
    }
    .tab .picture_section :deep(.upload_wpr .info){
        font-size: 10px;
        line-height: 12px;
    }
    .cell .picture_section > li{
        width: 100%;
    }


    .question-options {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 5px 0;
        gap: 20px;
    }
    .question-options label {
        display: flex;
        align-items: flex-start;
        cursor: pointer;
    }
    .question-options label p{
        font-size: 11px;
        line-height: 15px;
        padding-left: 10px;
    }
    .question-options .radio_box{
        width: 13px;
        height: 13px;
        /* border: 1px solid var(--var-element-color); */
        border: 1px solid #5a5a5a;
        border-radius: 50%;
        position: relative;
        display: block;
    }
    .question-options .radio_box .dot{
        position: absolute;
        /* background: var(--var-element-color); */
        background: #5a5a5a;
        border-radius: 50%;
        left: 2px;
        right: 2px;
        top: 2px;
        bottom: 2px;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }
    .question-options label input[type="radio"]:checked ~ .radio_box .dot{
        transform: scale(1);
    }
    .question-options label.checkbox span{
        /* border: 1px solid var(--var-element-color); */
        border: 1px solid #5a5a5a;
    }
    .question-options label.checkbox span i{
        /* color: var(--var-element-color);  */
        color: #5a5a5a;
    }

    .habit_title{
        display: flex;
        align-items: flex-start;
        gap: 12px;
    }
    .habit_title .title{
        flex: 1 1 auto;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: var(--var-element-text-color);
    }
    .cell .habit_title .title{
        font-size: 13px;
        line-height: 20px;
    }
    .habit_details {
        padding: 20px 0 0;
        display: none;
    }
    .cat_panel.active .quest_panel .quest_wpr .habit_details {
        display: block;
    }
    .habit_title label{
        width: 22px;
        height: 22px;
        border-radius: 50%;
        border: 1px solid var(--var-radio-background-color);
        color: var(--var-radio-background-color);
        font-size: 11px;
        line-height: 20px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        flex-shrink: 0;
        margin-top: 2px;
    }
    .cell .habit_title label{
        width: 18px;
        height: 18px;
        font-size: 9px;
        line-height: 16px;
    }
    .category_title span{
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #2f7eed;
        padding: 0 5px;
        line-height: 12px;
        border-radius: 10px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }
    .cell .category_title span{
        font-size: 11px;
        line-height: 14px;
    }
    .category_title span{
        color: #5a5a5a;
    }
    .category_title span.show, .cat_panel.active .category_title span.hide {
        display: block;
    }
    .category_title span.hide, .cat_panel.active .category_title span.show {
        display: none;
    }

    /* ------ Client-tracking card ------- */

    .tracking_title h1{
        font-size: 30px;
        line-height: 47px;
        font-weight: 500;
        color: #121525;
        margin-bottom: 5px;
        text-align: center;
    }
    .cell .tracking_title h1{
        font-size: 20px;
        line-height: 30px;
    }
    /* .tracking_title h4 {
        font-size: 13px;
        line-height: 18px;
        font-weight: 300;
        color: #5a5a5a;
        margin-bottom: 15px;
        text-align: center;
    } */
    .category_label{
        font-size: 20px;
        line-height: 25px;
        font-weight: 500;
        color: #121525;
        padding: 15px 0;
        display: flex;
        justify-content: center;
        position: relative;
    }
    .cell .category_label{
        font-size: 13px;
        line-height: 16px;
    }
    .category_label:before{
        content:'';
        position: absolute;
        top: 50%;
        left: 25%;
        right: 25%;
        border-bottom: 1px solid #d9d9d9;
        z-index: 0;
    }
    .tab .category_label:before, 
    .cell .category_label:before{
        left: 15%;
        right: 15%;
    }
    .category_label span{
        padding: 7px 15px;
        background: #fafafb;
        position: relative;
        z-index: 1;
    }
    .card_list{
        display: flex;
        flex-wrap: wrap;
        margin: 25px -15px 15px -15px;
    }
    .card_list li{
        width: 50%;
        padding: 0 15px;
    }
    .tab .card_list li,
    .cell .card_list li{
        width: 100%;
    }
    .water_card{
        background: #fff;
        border-radius: 8px;
        text-align: center;
        margin-bottom: 30px;
    }
    .top_area{
        padding: 20px 20px 30px 20px;
        background: #fff4e2;
        border-radius: 8px 8px 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .bottom_area{
        padding: 20px 20px 10px 20px;
        position: relative;
    }
    .bottom_area:before{
        position: absolute;
        content: '';
        bottom: 100%;
        left: 0;
        width: 100%;
        height: 30px;
        background: url('~@/assets/images/curve_bg.svg');
        background-size: contain;
    }
    .water_card img{
        max-height: 100px;
        width: auto;
        display: block;
        margin: 0 auto 10px auto;
    }
    .water_card .main_title{
        font-size: 18px;
        line-height: 25px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 10px;
    }
    .water_card .sub_text{
        font-size: 13px;
        line-height: 20px;
        color: #121525;
        font-weight: 400;
        padding: 0;
        margin-bottom: 15px;
    }
    .water_card .result{
        font-size: 20px;
        line-height: 23px;
        color: #121525;
        font-weight: 400;
        padding: 5px 0;
        margin-bottom: 15px;
    }
    .water_card .input_section{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 15px;
    }
    .water_card .input_section .field_box{
        flex: 1 0 50%;
        padding: 0 10px 10px 10px;
        display: flex;
        flex-direction: column;
    }
    .water_card .input_section label{
        width: 100%;
        font-size: 13px;
        line-height: 16px;
        color: #121525;
        font-weight: 400;
        text-align: left;
        padding: 10px 10px 7px 10px;
    }
    .water_card .input_section .field_box label{
        padding: 10px 0 7px 0;
    }
    .water_card .input_section .field_box .field_area{
        display: flex;
        align-items: center;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        position: relative;
    }
    .water_card .input_section input{
        height: 40px;
        width: 100%;
        padding: 0 10px;
        background: transparent;
        pointer-events: none;
    }
    .water_card .input_section .field_box .unit{
        background: #f5f5f5;
        border-left: 1px solid #e9e9e9;
        border-radius: 0 5px 5px 0;
        height: 40px;
        min-width: 40px;
        padding: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
    }
    .water_card .notes{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        max-height: 25px;
        overflow: hidden;
        margin-bottom: 15px;
        transition: all 0.3s ease-in-out;
    }
    .water_card .notes.active{
        max-height: 500px;
    }
    .water_card .notes .add_notes{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        background: #f5f5f5;
        padding: 5px 10px;
        border-radius: 12px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #121525;
        cursor: pointer;
    }
    .water_card .notes .add_notes i{
        margin-top: 2px;
        font-size: 8px;
    }
    .water_card .notes textarea{
        height: 80px;
        width: 100%;
        padding: 10px;
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        font-size: 11px;
        line-height: 15px;
        font-weight: 400;
        color: #121525;
        resize: vertical;
        pointer-events: none;
    }
    .water_card .notes .save_btn{
        padding: 4px 10px;
        border-radius: 3px;
        background: #2f7eed;
        color: #fff;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        margin-left: auto;
        cursor: pointer;
    }
    .water_card .picture_section{
        max-width: 400px;
        border-top: 1px dashed #e9e9e9;
        padding: 30px 0 0 0;
        margin: 30px auto 0;
    }
    .water_card .picture_section .input_section{
        max-width: 350px;
        margin: 0 auto 15px;
    }
    .water_card .picture_section :deep(.tab_content) {
        height: 100%;
    }
    .water_card .picture_section .upload_image{
        padding: 0 10px;
        width: 100%;
        margin-bottom: 0;
    }
    .water_card .picture_section .upload_image :deep(.upload_wpr .upload_area), .water_card .picture_section .upload_image :deep(.upload_wpr .upload_area){
        width: 100%;
        min-height: 150px;
        padding: 0;
        border: 0;
        background-color: #fafafb;
    }
    .water_card .picture_section :deep(.upload_wpr img){
        height: 25px;
        width: auto;
        opacity: 0.5
    }
    .water_card .picture_section .upload_image :deep(.upload_wpr .upload_area h4){
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #5a5a5a;
    }
    .water_card .picture_section .upload_image :deep(.upload_wpr .info){
        margin: 7px 0 !important;
        font-size: 11px;
        line-height: 14px;;
    }

    .form-area-thankyou a.link {
        text-decoration: none;
        font-weight: 500;
    }

    .cell .form-area-thankyou {
        max-width: 400px;
        margin: 40px auto;
        border-radius: 6px;
        padding: 25px;
    }

    .dashboard_footer {
        margin-top: auto;
        padding: 40px 0;
        background-color: #f5f5f5;
    }

    .form-area-thankyou {
        max-width: 800px;
        padding: 30px 40px;
        margin: 60px auto;
        flex: 1;
        border-radius: 12px;
        box-shadow: 0 0 35px rgba(0,0,0,0.03);
    }

    .cell .form-area-thankyou {
        max-width: 400px;
        margin: 40px auto;
        border-radius: 6px;
        padding: 15px;
    }

    .form_area {
        max-width: 800px;
        flex: 1;
        border-radius: 12px;
    }

    .form_area .field_wpr{
        background: #fff;
    }

    .cell .form_area {
        max-width: 400px;
        border-radius: 6px;
    }

    .submit_section{
        max-width: 600px;
        margin: 10px auto;
    }
    .submit_section .field_wpr.m_id_field{
        background: #e7e7e7;
        border: 0;
    }
    .submit_section .primary_btn{
        margin: 20px 0 0 0;
    }
</style>
